import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <hr></hr>
    <p>{`This guide is specifically designed for library staff who are responsible for setting up and configuring the Solus Library App. This comprehensive online resource aims to provide guidance on configuring the settings and options for your app that enable your patrons to access materials and services through your library catalog. Here's an overview of what you can expect from this guide:`}</p>
    <p><strong parentName="p">{`Configuring App Settings:`}</strong>{` You will learn how to customize the app to reflect the unique identity of your library. This includes utilizing images and colors that align with your library's branding and visual scheme. By personalizing the app, you can create a consistent and recognizable experience for your patrons.`}</p>
    <p><strong parentName="p">{`Providing Information`}</strong>{`: You can include essential information in the app, such as directions to the library, library hours, and links to web pages where patrons can access information about your library's programs and services. This ensures that your patrons have easy access to crucial details and enhances their engagement with your library's offerings.`}</p>
    <p>{`It's important to note that the sample images mentioned in this guide reflect the default settings commonly provided with newly created apps. Your specific system settings may differ based on your library's implementation choices and the configurations set.`}</p>
    <p>{`Solus is committed to providing services across a diverse range of Integrated Library Systems / Library Management Systems. As a result, certain fields and settings referenced in these help files may not directly correspond to your specific configuration. For the best compatibility and functionality, we suggest directly contacting your Integrated Library System/Library Management System provider. Their expertise in your specific configuration enables precise verification and alignment with Solus services. `}</p>
    <p>{`If you require assistance, have specific questions regarding the configuration process or encounter discrepancies regarding specific fields that seem unrelated to your setup, please contact Solus at `}<a parentName="p" {...{
        "href": "mailto:support@sol.us."
      }}>{`support@sol.us.`}</a>{` `}</p>
    <h6 {...{
      "id": "last-updated-april-26th-2024"
    }}>{`Last updated April 26th, 2024`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      